var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-0 mb-3 px-3"},[_c('div',{staticClass:"col-md-6"},[_c('a',{staticClass:"btn btn-block btn-dark",attrs:{"data-toggle":"collapse","href":"#combustible","role":"button","aria-expanded":"false","aria-controls":"collapseExample"}},[_vm._v(" Factor de Combustible ")]),_c('div',{staticClass:"collapse",attrs:{"id":"combustible"}},[_c('div',{staticClass:"card card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                _vm.$v.form.tipo_vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
              ],attrs:{"label":"nombre","options":_vm.listasForms.tiposVehiculosList,"placeholder":"Tipos de Vehículo"},model:{value:(_vm.form.tipo_vehiculo),callback:function ($$v) {_vm.$set(_vm.form, "tipo_vehiculo", $$v)},expression:"form.tipo_vehiculo"}})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.factor_combustible),expression:"form.factor_combustible"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.factor_combustible.$invalid
                  ? 'is-invalid'
                  : 'is-valid',attrs:{"type":"number","placeholder":"Cantidad Galones"},domProps:{"value":(_vm.form.factor_combustible)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "factor_combustible", $event.target.value)}}})]),_c('div',{staticClass:"col-md-2"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid),expression:"!$v.form.$invalid"}],staticClass:"btn bg-info col-md-12",on:{"click":function($event){return _vm.cargarFactorCombustible()}}},[_c('i',{staticClass:"fas fa-save"})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm"},[_vm._m(0),_c('tbody',_vm._l((_vm.factor_combustible),function(data,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(data.nombre)+" ")]),_c('td',[_vm._v(" "+_vm._s(data.pivot.factor_combustible)+" ")]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyProceso(data.id, data.pivot.sitio_id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])])}),0)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Tipo de Vehículo")]),_c('th',[_vm._v("Factor de Combustible")]),_c('th',{staticStyle:{"width":"20px"}},[_vm._v("Opciones")])])])
}]

export { render, staticRenderFns }
<template>
  <div class="row mt-0 mb-3 px-3">
    <div class="col-md-6">
      <a
        class="btn btn-block btn-dark"
        data-toggle="collapse"
        href="#combustible"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        Factor de Combustible
      </a>
      <div class="collapse" id="combustible">
        <div class="card card-body">
          <div class="row">
            <div class="form-group col-md-6">
              <v-select
                :class="[
                  $v.form.tipo_vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                v-model="form.tipo_vehiculo"
                label="nombre"
                :options="listasForms.tiposVehiculosList"
                class="form-control form-control-sm p-0"
                placeholder="Tipos de Vehículo"
              ></v-select>
            </div>
            <div class="form-group col-md-4">
              <input
                type="number"
                v-model="form.factor_combustible"
                placeholder="Cantidad Galones"
                class="form-control form-control-sm"
                :class="
                  $v.form.factor_combustible.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
            </div>
            <div class="col-md-2">
              <button
                class="btn bg-info col-md-12"
                v-show="!$v.form.$invalid"
                @click="cargarFactorCombustible()"
              >
                <i class="fas fa-save"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table
                class="table table-bordered table-striped table-hover table-sm"
              >
                <thead>
                  <tr>
                    <th>Tipo de Vehículo</th>
                    <th>Factor de Combustible</th>
                    <th style="width: 20px">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in factor_combustible" :key="index">
                    <td>
                      {{ data.nombre }}
                    </td>
                    <td>
                      {{ data.pivot.factor_combustible }}
                    </td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        @click="destroyProceso(data.id, data.pivot.sitio_id)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "SitioFactorCombustible",
  components: {
    vSelect,
  },
  data() {
    return {
      form: {},
      listasForms: {
        tiposVehiculosList: [],
      },
      factor_combustible: [],
    };
  },

  validations: {
    form: {
      tipo_vehiculo: {
        required,
      },
      factor_combustible: {
        required,
      },
    },
  },

  methods: {
    /*     async getTiposVehiculos(tipo_vehiculos) {
      axios
        .get("/api/admin/tiposVehiculos/lista", {
          params: {
            not_id: tipo_vehiculos,
          },
        })
        .then((response) => {
          this.listasForms.tiposVehiculosList = response.data;
        });
    }, */

    async getTiposVehiculos(tipo_vehiculos) {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tiposVehiculosList = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getSitiosHasFactorCombustible() {
      axios
        .get("/api/admin/sitios/show/" + this.$parent.form.id)
        .then((response) => {
          if (response.data[0].procesos != undefined) {
            this.factor_combustible = response.data[0].factor_combustible;
            let tipo_vehiculos = new Array();
            response.data[0].factor_combustible.forEach((factor) => {
              tipo_vehiculos.push(factor.pivot.tipo_vehiculo_id);
            });
            this.getTiposVehiculos(tipo_vehiculos);
          }
        });
    },

    cargarFactorCombustible() {
      this.form.tipo_vehiculo_id = this.form.tipo_vehiculo.id;
      this.form.factor_combustible = parseInt(this.form.factor_combustible);
      this.form.sitio_id = this.$parent.form.id;
      this.form.accion = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: "PUT",
          url: "/api/admin/sitios/assingOrRevokeFactorCombustible",
          data: this.form,
        })
          .then(() => {
            this.form = {};
            this.$swal({
              icon: "success",
              title: "Se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getSitiosHasFactorCombustible();
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, el proceso ya se encuentra asignado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroyProceso(idTipoVehiculo, idSitio) {
      this.$swal({
        title: "Está seguro de eliminar este Factor de Combustible?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios({
            method: "PUT",
            url: "/api/admin/sitios/assingOrRevokeFactorCombustible",
            data: {
              sitio_id: idSitio,
              tipo_vehiculo_id: idTipoVehiculo,
              accion: false,
            },
          }).then(() => {
            this.getSitiosHasFactorCombustible();
            this.$swal({
              icon: "success",
              title: "Se eliminó el Factor de Combustible exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },

  mounted() {
    this.getSitiosHasFactorCombustible();
  },
};
</script>
